import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {
  IdentityProviderConnectionService,
  ImportJobPageResultDto,
  ImportJobViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {TranslationHelperService} from 'src/app/services/helper/translation-helper.service';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS} from '../../../../../shared/lancrypt.constants';
import {MatDialog} from '@angular/material/dialog';
import {ConnectionImportJobDetailsComponent} from '../connection-import-job-details/connection-import-job-details.component';

@Component({
  selector: 'app-connection-import-jobs',
  templateUrl: './connection-import-jobs.component.html',
  styleUrls: ['./connection-import-jobs.component.scss'],
})
export class ConnectionImportJobsComponent implements OnInit {
  dataSource: MatTableDataSource<ImportJobViewDto>;
  displayedColumns: string[] = ['name', 'importedGroupsAndUsers', 'startedAt', 'status'];
  api: IdentityProviderConnectionService;
  tenantId?: string;

  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  constructor(
    private _apiClientFactory: ApiClientFactoryService,
    private _jwtHelper: JwtHelperService,
    private translationHelperService: TranslationHelperService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<ImportJobViewDto>();
    this.api = _apiClientFactory.getIdentityProviderConnectionService();
  }

  ngOnInit(): void {
    this._jwtHelper.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadImportJobs();
    });
  }

  private loadImportJobs(): void {
    if (!this.tenantId) {
      return;
    }

    this.api.getImportJobs(this.tenantId!, this.pageSize, this.currentPage).subscribe({
      next: (n: ImportJobPageResultDto) => {
        this.totalRows = n.totalElements;
        this.dataSource.data = n.content;
      },
      error: (_: any) => {},
    });
  }

  openDetailsDialog(dto: ImportJobViewDto) {
    this.dialog.open(ConnectionImportJobDetailsComponent, {
      maxWidth: '500px',
      hasBackdrop: true,
      data: dto.statusJson,
    });
  }

  renderGroupsAndUsersText(dto: ImportJobViewDto): string {
    if (dto.status === 'IN_PROGRESS') {
      return this.translateService.instant('common.pending');
    }

    return this.translationHelperService.renderUsersAndGroupsText(
      dto.numberOfUsersImported,
      dto.numberOfGroupsImported
    );
  }

  renderConnectionNameText(dto: ImportJobViewDto): string {
    if (dto.connectionName !== null) {
      return dto.connectionName;
    } else {
      return this.translateService.instant('identityProviderConnection.deleted');
    }
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadImportJobs();
  }

  mapStatus(dto: ImportJobViewDto): string {
    switch (dto.status) {
      case 'COMPLETED':
        return 'connectionImport.statusCompleted';
      case 'FAILED':
        return 'connectionImport.statusFailed';
      case 'IN_PROGRESS':
        return 'connectionImport.statusInProgress';
    }
  }
}
