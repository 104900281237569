<div id="identity-provider-container">
  <div class="headline-container">
    <h1>{{ 'headlines.identityProviderConnections' | translate }}</h1>
  </div>
  <mat-divider class="headline-divider"></mat-divider>

  <mat-card>
    <mat-card-header class="header">
      <div class="control-elements">
        <button matTooltip="{{ 'buttons.addIdentityProviderConnection' | translate }}" mat-mini-fab
                color="lancrypt-primary"
                [routerLink]="['/lancrypt/connections/create-connection']">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'identityProviderConnection.name' | translate }}</th>
          <td mat-cell *matCellDef="let connection">{{ connection.name }}</td>
        </ng-container>

        <ng-container matColumnDef="connectionType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'identityProviderConnection.type' | translate }}</th>
          <td mat-cell
              *matCellDef="let connection">{{ 'identityProviderConnection.connectionType.' + connection.connectionType | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activeJob">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let connection">
            <span *ngIf="connection.activeImportJob">{{ 'identityProviderConnection.importIsActive' | translate }}</span>
            <span *ngIf="connection.activeDeletionJob">{{ 'identityProviderConnection.deletionIsActive' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let connection">
            <button mat-icon-button (click)="importConnection(connection.id); $event.stopPropagation()"
                    [disabled]="!allowAction(connection)"
                    matTooltip="{{ 'buttons.syncIdentityProviderConnection' | translate }}">
              <mat-icon>sync</mat-icon>
            </button>
            <button mat-icon-button (click)="editConnection(connection); $event.stopPropagation()"
                    [disabled]="!allowAction(connection)"
                    matTooltip="{{ 'buttons.editIdentityProviderConnection' | translate }}">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteConnection(connection.id); $event.stopPropagation()"
                    [disabled]="!allowAction(connection)"
                    matTooltip="{{ 'buttons.deleteIdentityProviderConnection' | translate }}">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="editConnection(row)" *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
            <div *onlyShowWhenIdle>
              {{ 'identityProviderConnection.noConnectionsFound' | translate }}
            </div>
          </td>
        </tr>
      </table>
    </mat-card-content>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
