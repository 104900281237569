<div class="settings-container">
  <div class="headline-container">
    <h1>{{'headlines.settings' | translate}}</h1>
  </div>
  <mat-divider class="headline-divider"></mat-divider>
  <mat-accordion multi="true">
    <app-settings-sync [settingsViewDto]="settingsViewDto"></app-settings-sync>
    <app-settings-file-encryption [settingsViewDto]="settingsViewDto"></app-settings-file-encryption>
    <app-settings-outlook [removeIfFeatureIsOff]="'outlook'" [settingsViewDto]="settingsViewDto" [tenantId]="tenantId"></app-settings-outlook>
    <app-settings-keyserver [removeIfFeatureIsOff]="'keyserver'" [settingsViewDto]="settingsViewDto" [tenantId]="tenantId"></app-settings-keyserver>
  </mat-accordion>
  <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
</div>
