import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatButton} from '@angular/material/button';
import {CdkCopyToClipboard} from '@angular/cdk/clipboard';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-connection-import-job-details',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    NgxJsonViewerModule,
    TranslateModule,
    MatDialogActions,
    MatButton,
    CdkCopyToClipboard,
    MatDialogClose,
    NgIf,
  ],
  templateUrl: './connection-import-job-details.component.html',
  styleUrl: './connection-import-job-details.component.scss',
})
export class ConnectionImportJobDetailsComponent implements OnInit {
  payload: any = {};
  error = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    try {
      this.payload = JSON.parse(this.data);
      Object.values(this.payload.steps).forEach((step: any) => {
        if (step.error) {
          delete step.error.message;
          step.error.field = this.translateService.instant('connectionImport.error.' + step.error.field);

          //rename the "field" object
          step.error.message = step.error.field;
          delete step.error.field;
        }
      });
    } catch (_) {
      this.payload = null;
      this.error = this.translateService.instant('connectionImport.error.noDetails');
    }
  }

  protected readonly JSON = JSON;
}
