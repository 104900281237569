<div id="import-jobs-container">
  <div class="headline-container">
    <h1>{{ 'headlines.identityProviderImportJobs' | translate }}</h1>
  </div>
  <mat-divider class="headline-divider"></mat-divider>

  <mat-card>
    <mat-card-content>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "identityProviderConnection.name" | translate }}</th>
          <td mat-cell *matCellDef="let job"
              [ngClass]="{'strikethrough': job.deleted}">{{ renderConnectionNameText(job) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="importedGroupsAndUsers">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>{{ "connectionImport.importedGroupsAndUsers" | translate }}
          </th>
          <td mat-cell *matCellDef="let job">{{ renderGroupsAndUsersText(job) }}</td>
        </ng-container>

        <ng-container matColumnDef="startedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "connectionImport.jobStartedAt" | translate }}</th>
          <td mat-cell *matCellDef="let job">{{ job.startedAt | date: 'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "connectionImport.status" | translate }}</th>
          <td mat-cell *matCellDef="let job">{{ mapStatus(job) | translate }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetailsDialog(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
            <div *onlyShowWhenIdle>
              {{ 'identityProviderConnection.noRecentImportJobs' | translate }}
            </div>
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalRows"
                     [pageIndex]="currentPage" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    </mat-card-content>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
