import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {
  SettingsOutlookDto,
  SettingsOutlookExcludedAttachmentDto,
  SettingsOutlookInternalDomainDto,
  SettingsService,
  SettingsViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS} from '../../../../../shared/lancrypt.constants';

@Component({
  selector: 'app-settings-outlook',
  templateUrl: './settings-outlook.component.html',
  styleUrls: ['../../lancrypt-settings.component.scss'],
})
export class SettingsOutlookComponent implements OnInit, OnChanges {
  expanded = false;
  editMode = false;
  @Input() settingsViewDto?: SettingsViewDto;
  @Input() tenantId?: string;
  formGroup;

  private settingsApi: SettingsService;

  maxLengthConstraints = {
    internalDomain: FIELD_LENGTH_CONSTRAINTS.outlookSettings.internalDomain,
    excludedAttachment: FIELD_LENGTH_CONSTRAINTS.outlookSettings.excludedAttachment,
  };

  constructor(
    private _formBuilder: FormBuilder,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private translationService: TranslateService
  ) {
    this.formGroup = this._formBuilder.group({
      checkOnSend: new FormControl<boolean>(true),
      internalDomains: this._formBuilder.array<string>([]),
      excludedAttachments: this._formBuilder.array<string>([]),
    });

    this.settingsApi = apiClientFactory.getSettingsService();
  }

  ngOnInit() {
    this.formGroup.disable();
  }

  ngOnChanges(): void {
    this.updateData();
    if (!this.editMode && !this.formGroup.disabled) {
      this.formGroup.disable();
    }
  }

  private updateData(): void {
    if (!this.settingsViewDto?.outlook) {
      return;
    }

    this.patchArray(
      this.formGroup.controls.internalDomains,
      this.settingsViewDto.outlook.internalDomains?.map(d => d.domain),
      this.maxLengthConstraints.internalDomain
    );

    this.patchArray(
      this.formGroup.controls.excludedAttachments,
      this.settingsViewDto.outlook.excludedAttachments?.map(x => x.attachment),
      this.maxLengthConstraints.excludedAttachment
    );

    this.formGroup.patchValue({
      checkOnSend: this.settingsViewDto.outlook?.checkOnSend,
    });
  }

  private patchArray(formArray: FormArray, values: (string | undefined)[] | undefined, max: number) {
    formArray.clear();

    values?.forEach(v => {
      if (v) {
        formArray.push(new FormControl<string>(v, [Validators.maxLength(max)]));
      }
    });
  }

  onEdit(): void {
    // Add single empty fields at the end
    this.addInternalDomain();
    this.addExcludedAttachment();

    this.editMode = true;
    this.expanded = true;
    this.formGroup.enable();
  }

  async onSave() {
    if (!this.formGroup.valid) {
      return;
    }

    this.updateOutlookSettings();
  }

  get internalDomains() {
    return this.formGroup.controls.internalDomains;
  }

  addInternalDomain() {
    this.formGroup.controls.internalDomains.push(
      new FormControl<string>('', [Validators.maxLength(this.maxLengthConstraints.internalDomain)])
    );
  }

  deleteInternalDomain(index: number) {
    this.formGroup.controls.internalDomains.removeAt(index);
  }

  get excludedAttachments() {
    return this.formGroup.controls.excludedAttachments;
  }

  addExcludedAttachment() {
    this.formGroup.controls.excludedAttachments.push(
      new FormControl<string>('', [Validators.maxLength(this.maxLengthConstraints.excludedAttachment)])
    );
  }

  deleteExcludedAttachment(index: number) {
    this.formGroup.controls.excludedAttachments.removeAt(index);
  }

  private collectDomains(): SettingsOutlookInternalDomainDto[] {
    const domains: SettingsOutlookInternalDomainDto[] = [];
    this.formGroup.controls.internalDomains.controls.forEach(d => {
      const value = d.value?.trim();
      if (value && value.length > 0) {
        domains.push({domain: value});
      }
    });
    return domains;
  }

  private collectAttachments(): SettingsOutlookExcludedAttachmentDto[] {
    const attachments: SettingsOutlookExcludedAttachmentDto[] = [];
    this.formGroup.controls.excludedAttachments.controls.forEach(a => {
      const value = a.value?.trim();
      if (value && value.length > 0) {
        attachments.push({attachment: value});
      }
    });
    return attachments;
  }

  updateOutlookSettings() {
    const updateDto: SettingsOutlookDto = {
      checkOnSend: this.formGroup.controls.checkOnSend.value!,
      internalDomains: this.collectDomains(),
      excludedAttachments: this.collectAttachments(),
    };

    this.settingsApi.updateSettingsOutlook(updateDto, this.tenantId!).subscribe({
      next: async () => {
        this.toastService.showSuccess(
          this.translationService.instant('settings.saveToast.title'),
          this.translationService.instant('settings.saveToast.successful')
        );
        this.settingsViewDto!.outlook = updateDto;
        this.updateData();
        this.editMode = false;
        this.formGroup.disable();
      },
      error: async (e: HttpErrorResponse) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('settings.errors.saving', {
            error: e.status,
          })
        );
      },
    });
  }

  onCancel(): void {
    this.updateData();
    this.editMode = false;
    this.formGroup.disable();
  }
}
