<h2 mat-dialog-title>{{ 'connectionImport.detailsTitle' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <ngx-json-viewer [json]="this.payload" *ngIf="this.payload"></ngx-json-viewer>
    <div *ngIf="!this.payload && this.error">{{ this.error }}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="lancrypt-primary"
            [cdkCopyToClipboard]="JSON.stringify(this.payload,null,2)">{{ 'auditlogs.copyToClipboard' | translate }}
    </button>
    <button mat-raised-button mat-dialog-close>{{ 'auditlogs.close' | translate }}</button>
</mat-dialog-actions>
