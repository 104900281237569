<mat-expansion-panel class="mat-elevation-z1" [(expanded)]="expanded">
    <mat-expansion-panel-header class="title-card-header">
        <mat-panel-title class="settings-header">
            <h2>{{'settings.outlook.title' | translate}}</h2>
            <mat-icon class="settings-info-icon" matTooltip="{{'settings.outlook.hint' | translate}}">info</mat-icon>
        </mat-panel-title>
        <mat-action-row (click)=$event.stopPropagation()>
            <button mat-button class="edit-button" (click)="onEdit()" color="lancrypt-primary-light"
                [disabled]="!settingsViewDto" [hidden]="editMode">
                {{'buttons.edit' | translate}}
            </button>
            <button mat-button class="save-button" (click)="onSave()" color="lancrypt-primary-light"
                [hidden]="!editMode">
                {{'buttons.save' | translate}}
            </button>
            <button mat-button class="cancel-button" (click)="onCancel()" [hidden]="!editMode">
                {{'buttons.cancel' | translate}}
            </button>
        </mat-action-row>
    </mat-expansion-panel-header>
    <mat-card style="box-shadow: none">
        <mat-card-content>
            <form [formGroup]="formGroup">
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.outlook.checkOnSend' | translate}}</mat-label>
                        <mat-icon class="settings-info-icon" matTooltip="{{'settings.outlook.checkOnSendInfo' | translate}}">info</mat-icon>
                    </span>
                    <span class="settings-value">
                        <mat-slide-toggle [formControl]="formGroup.controls.checkOnSend" class="settings-field"></mat-slide-toggle>
                    </span>
                </div>
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.outlook.internalDomains' | translate}}</mat-label>
                        <mat-icon class="settings-info-icon" matTooltip="{{'settings.outlook.internalDomainsInfo' | translate}}">info</mat-icon>
                    </span>
                    <span class="settings-value">
                        <ng-container *ngIf="!editMode && internalDomains.controls.length === 0">
                          {{'settings.outlook.internalDomainsSameAsSender' | translate}}
                        </ng-container>
                        <ng-container formArrayName="internalDomains" class="settings-list">
                            <ng-container *ngFor="let internalDomain of internalDomains.controls; let domIdx = index">
                                <div class="settings-value-full">
                                    <mat-form-field class="settings-growing">
                                        <input matInput [formControlName]=domIdx placeholder="{{'settings.outlook.exampleDomain' | translate}}">
                                        <mat-error *ngIf="internalDomain.hasError('maxlength')">
                                            {{'settings.errors.domainMaxLength' | translate: {maxLength:
                                          maxLengthConstraints.internalDomain} }}
                                        </mat-error>
                                    </mat-form-field>
                                    <button [hidden]="!editMode" mat-icon-button class="trashcan settings-space-before"
                                            (click)="deleteInternalDomain(domIdx); $event.stopPropagation()" disableDuringRequest
                                            matTooltip="{{ 'buttons.remove' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button class="settings-list" mat-mini-fab (click)="addInternalDomain()" [hidden]="!editMode">
                            <mat-icon matTooltip="{{'buttons.add' | translate}}">add</mat-icon>
                        </button>
                    </span>
                </div>
                <div class="settings-item">
                    <span class="settings-title">
                        <mat-label class="settings-name">{{'settings.outlook.excludedAttachments' | translate}}</mat-label>
                        <mat-icon class="settings-info-icon" matTooltip="{{'settings.outlook.excludedAttachmentsInfo' | translate}}">info</mat-icon>
                    </span>
                    <span class="settings-value">
                        <ng-container formArrayName="excludedAttachments" class="settings-list">
                            <ng-container *ngFor="let excludedAttachment of excludedAttachments.controls; let attIdx = index">
                                <div class="settings-value-full">
                                    <mat-form-field class="settings-growing">
                                        <input matInput [formControlName]=attIdx placeholder="{{'settings.outlook.exampleAttachment' | translate}}">
                                        <mat-error *ngIf="excludedAttachment.hasError('maxlength')">
                                            {{'settings.errors.attachmentMaxLength' | translate: {maxLength:
                                          maxLengthConstraints.excludedAttachment} }}
                                        </mat-error>
                                    </mat-form-field>
                                    <button [hidden]="!editMode" mat-icon-button class="trashcan settings-space-before"
                                            (click)="deleteExcludedAttachment(attIdx); $event.stopPropagation()" disableDuringRequest
                                            matTooltip="{{ 'buttons.remove' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button class="settings-list" mat-mini-fab (click)="addExcludedAttachment()" [hidden]="!editMode">
                            <mat-icon matTooltip="{{'buttons.add' | translate}}">add</mat-icon>
                        </button>
                    </span>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</mat-expansion-panel>
