import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LancryptSettingsComponent} from './lancrypt-settings.component';
import {SettingsSyncComponent} from './components/settings-sync/settings-sync.component';
import {SettingsFileEncryptionComponent} from './components/settings-file-encryption/settings-file-encryption.component';
import {SettingsOutlookComponent} from './components/settings-outlook/settings-outlook.component';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SettingsKeyserverComponent} from './components/settings-keyserver/settings-keyserver.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RemoveIfFeatureIsOffDirective} from 'src/app/shared/remove-if-feature-is-off.directive';

@NgModule({
  declarations: [
    LancryptSettingsComponent,
    SettingsSyncComponent,
    SettingsFileEncryptionComponent,
    SettingsKeyserverComponent,
    SettingsOutlookComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSliderModule,
    MatTooltipModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSliderModule,
    BusyServiceModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    FormsModule,
    RemoveIfFeatureIsOffDirective,
  ],
})
export class LancryptSettingsModule {}
